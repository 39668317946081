<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
            <v-col lg="6"
              md="6"
                  sm="12"
                  cols="12">
                <v-row align="center" justify="center" >
                  <v-col align="center" justify="center" >
                    <v-img data-aos="fade-left" data-aos-duration="1200" width="650" src="@/assets/images/blog/categories.png" alt="Creative Agency" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" >
                  <p>Κατέβασε το app</p><br>
                </v-row>
                <v-row align="center" justify="center" >
                  <v-col align="right">
                    <a href="https://apps.apple.com/us/app/id1489983713"  target="blank" class="app-btn1"><v-img width="100" src="@/assets/images/blog/appstore-tra-grey.png" alt=""/></a>
                  </v-col>
                  
                  <v-col>
                    <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba"  target="blank" class="app-btn2"><v-img width="100" src="@/assets/images/blog/googleplay-tra-grey.png" alt="" /></a>
                  </v-col>
                </v-row>
            </v-col>
              
               <v-col lg="6"
                     md="6"
                     sm="12"
                     cols="12">
                  <div class="single-column mt--50 ml--50 mr--50">
                        <div id="giftsList" v-for="(gift, i) in gifts.gifts"
                              :key="i">
                          <div class="mb--50">
                              <v-row>
                                <v-col lg="3"
                                      md="3"
                                      sm="12"
                                      cols="12">
                                      <img :src="`${gift.image}`" width="100px" />
                                </v-col>
                                <v-col lg="6"
                                      md="6"
                                      sm="12"
                                      cols="12">
                                      
                                      <h6><b> {{ gift.title }} </b></h6>
                                      <p class="isBlue"> {{ gift.points }} πόντοι </p>
                                      <p> {{ gift.description }} </p>
                                      <div v-if="permission">
                                    <v-btn v-if="gift.promo_type != 'product'" @click="gift.promo_type === 'product' ? redeemProduct() : redeemCoupon(gift.code)"
                                     :disabled="gift.points > user.total_points" class="btn" variant="primary">Εξαργύρωση</v-btn>
                                    <p class="isGrey" v-if="gift.points > user.total_points && gift.promo_type != 'product'">Δεν έχεις αρκετούς πόντους για αυτό το δώρο</p>
                                    <div v-if="gift.promo_type === 'product'">
                                        <RedeemCouponForm :code="gift.code" :promo_type="gift.promo_type"
                                         :points="gift.points" :total_points="user.total_points" :dialog=false />
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                              
                              
                          </div>

                              <br><br>
                          </div>
                  </div>
              </v-col>
                           
          </v-row>
          <b-pagination
            v-if="this.gifts.pagination.total_pages > 1"
            v-model="currentPage"
            :per-page="this.gifts.pagination.items_per_page"
            :total-rows="this.gifts.pagination.total"
            aria-controls="giftsList"
            v-on:click.native="scrollToTop"
            align="center"
          ></b-pagination>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import Gifts from "@/components/gifts/Gifts";
  import RedeemCouponForm from "@/views/RedeemCouponForm";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import FlashMessage from '@smartweb/vue-flash-message';
  import pdf from 'vue-pdf'


  export default {
    name: 'categories',
    data() {
        return  {
          currentPage: 1,
          perPage: 5,
          errors: '',
            valid: true,
            dialog: false,
            mobile: '',
            floor: '',
            bell_name: '',
            city: '',
            postalCode: '',
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            mobileRules: [
              v => !!v || 'Το κινητό τηλέφωνο είναι υποχρεωτικό',
              v => /^\d+$/.test(v) || 'Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς',
              v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
            ],
            addressRules: [
              v => !!v || 'Η διεύθυνση είναι υποχρεωτική',
            ],
            postalCodeRules: [
              v => !!v || 'Ο ταχυδρομικός κώδικας είναι υποχρεωτικός',
            ],
            cityRules: [
              v => !!v || 'Η πόλη είναι υποχρεωτική',
            ],
            firstNameRules: [
              v => !!v || 'Το όνομα είναι υποχρεωτικό',
            ],
            lastNameRules: [
              v => !!v || 'Το επίθετο είναι υποχρεωτικό',
            ],
            emailRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
              v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
            ],
            floorRules: [
              v => !!v || 'Ο όροφος είναι υποχρεωτικός',
              v => /^\d+$/.test(v) || 'Ο όροφος πρέπει να περιέχει μόνο αριθμούς',
            ],
            bellNameRules: [
              v => !!v || 'Το κουδούνι είναι υποχρεωτικό',
            ],
        }
    },
     
    components: {
      Header,
      Footer,
      RedeemCouponForm,
      Gifts,
      pdf
    },
    computed: {
       ...mapGetters (["gifts"]),
       ...mapGetters (["user"]),
       giftId(){
         return this.$route.params.id;
       },
      permission() {
        return this.$store.state.user;
      },
    },
    async  created () {
      this.getGifts([this.$route.params.id, 1]);
      this.getUser();
    },
    watch: {
      giftId: function(id){
        this.currentPage = 1;
        this.getGifts([id, 1]);
       },
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getGifts([this.giftId, newQuestion]);
      }
    },
    methods: {
      scrollToTop() {
        this.$vuetify.goTo(0);
      },
      ...mapActions(["getGifts"]),
      ...mapActions(["getUser"]),
      async redeemCoupon(couponCode){
        try{
          this.$loading(true);
          const response = await axios.post('external/me/transactions/coupon-claim', 
          {
              code: couponCode,
          }).then(response => {
            console.log(response)
              this.$loading(false);
              let textAns = '<span>' + 'Συγχαρητήρια!' + '</br>' +
                                ' Πήρες το δώρο!' + '</br>' + 'Ο κωδικός είναι: ' +
                                '</br>' + response.data.data.voucher + '</span>';
              let testt = 'Θα βρείτε τον κωδικό στην κατηγορία Εξαργυρώσεις';
              this.$swal({
                  html: testt,
                  title: textAns,
                  icon: "success", //built in icons: success, warning, error, info
                  // timer: 15000,
                  buttons: {
                    confirm: {
                      text: "OK",
                      value: true,
                      visible: true,
                      className: "",
                      closeModal: true
                    },
                    cancel: {
                      text: "Cancel",
                      value: false,
                      visible: true,
                      className: "",
                      closeModal: true,
                    }
                  }
                });

          }).catch(error => {
              this.$loading(false);
              this.$swal({
                title: "Αποτυχία",
                text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
                icon: "error", //built in icons: success, warning, error, info
                timer: 3000,
                  buttons: {
                    confirm: {
                      text: "OK",
                      value: true,
                      visible: true,
                      className: "",
                      closeModal: true
                    },
                    cancel: {
                      text: "Cancel",
                      value: false,
                      visible: true,
                      className: "",
                      closeModal: true,
                    }
                  }
              });

              if (error.response.data.code == 401) {
                  this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
              } 
          });      
        }catch(e){
            this.error = e.message;
            return e;
        }
      }
    },
  };
</script>


<style scoped>
.btn {
    background: #004a91;
}
.isGrey{
  color: #ada7a7;
}
.isGreyNumber{
  color: #716c6c;
}
.isBlue{
  color: blue;
}
 a:hover {
    color: #2496d4 !important;
  }
  .v-application a {
    color: #222;
    text-decoration: none !important;
  }
</style>